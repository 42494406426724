import './style.scss'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { SplitText } from "gsap/SplitText"
import { SlowMo } from "gsap/EasePack"

gsap.registerPlugin(ScrollTrigger, SplitText)


/**
 * Title
 */
const title = document.querySelector('#main-title')
const split = new SplitText(title, {type:'lines,words', linesClass: 'split-word'})
for (const line of split.lines) {
	gsap.set(line, {
		overflow: 'hidden',
		position: 'relative',
		paddingBottom: 10
	})
}

const intro = gsap.timeline()
intro.from(split.words,{
	opacity: 0,
	yPercent: 100,
	stagger: 0.07,
	duration: 3,
	ease: "Power3.easeInOut"
}, .65)

/**
 * Back animation
 */
const back = document.getElementById("opening");
intro.addLabel('startBack', 1)
intro.from(back, {
	opacity: 0,
	duration: 2,
}, 'startBack+=.25')
intro.from(back, {
	duration: 3,
	scale: 1.25,
	ease: "Expo.easeOut"
}, 'startBack')

/**
 * Canvas animation
 */
//
// const canvas = document.getElementById("hero-animation");
// intro.addLabel('startBack', 1)
// intro.from(canvas, {
// 	opacity: 0,
// 	duration: 2,
// }, 'startBack+=.25')
// intro.from(canvas, {
// 	duration: 3,
// 	scale: 1.25,
// 	ease: "Expo.easeOut"
// }, 'startBack')
//
// const context = canvas.getContext("2d");
//
// canvas.width= window.innerWidth;
// canvas.height= window.innerHeight;
//
// const frameCount = 70;
// const currentFrame = index => (
// 	`/animation/${(index + 1).toString().padStart(4, '0')}.jpg`
// );
//
// const images = []
// const airpods = {
// 	frame: 0
// };
//
// for (let i = 0; i < frameCount; i++) {
// 	const img = new Image();
// 	img.src = currentFrame(i);
// 	images.push(img);
// }
//
// gsap.to(airpods, {
// 	frame: frameCount - 1,
// 	snap: "frame",
// 	scrollTrigger: {
// 		scrub: 0.5,
// 		onUpdate: () => {
// 		}
// 	},
// 	onUpdate: render // use animation onUpdate instead of scrollTrigger's onUpdate
// });
// console.log(airpods.frame)
// images[airpods.frame].onload = render;
//
// function render() {
// 	context.clearRect(0, 0, canvas.width, canvas.height);
//
// 	const imgW = window.innerWidth
// 	const imgH = window.innerWidth * 9 / 16
//
// 	const transY = (imgH - window.innerHeight) / 2 * -1
//
// 	context.drawImage(images[airpods.frame], 0, transY, imgW, imgH);
// }


// const frameCount = 70;
// const currentFrame = index => (
// 	`/animation/${index.toString().padStart(4, '0')}.jpg`
// )
//
//
// const preloadImages = () => {
// 	for (let i = 1; i < frameCount; i++) {
// 		const img = new Image();
// 		img.src = currentFrame(i);
// 	}
// };
//
// const img = new Image()
// img.src = currentFrame(1);
// canvas.width= window.innerWidth;
// canvas.height= window.innerHeight;
// img.onload=function(){
// 	const imgW = window.innerWidth
// 	const imgH = window.innerWidth * 9 / 16
//
// 	const transY = (imgH - window.innerHeight) / 2 * -1
// 	console.log(transY)
//
// 	console.log(imgW, imgH)
// 	context.drawImage(img, 0, transY, imgW, imgH);
// }
//
// const updateImage = index => {
// 	img.src = currentFrame(index);
// 	console.log(img.src)
// 	// context.drawImage(img, 0, 0);
// }
//
// window.addEventListener('scroll', () => {
// 	const scrollTop = html.scrollTop;
// 	const maxScrollTop = html.scrollHeight - window.innerHeight;
// 	const scrollFraction = scrollTop / maxScrollTop;
// 	const frameIndex = Math.min(
// 		frameCount - 1,
// 		Math.ceil(scrollFraction * frameCount)
// 	);
//
// 	requestAnimationFrame(() => updateImage(frameIndex + 1))
// });
//
// window.addEventListener('resize', (event) => {
// 	canvas.width= window.innerWidth;
// 	canvas.height= window.innerHeight;
// 	const scrollTop = html.scrollTop;
// 	const maxScrollTop = html.scrollHeight - window.innerHeight;
// 	const scrollFraction = scrollTop / maxScrollTop;
// 	const frameIndex = Math.min(
// 		frameCount - 1,
// 		Math.ceil(scrollFraction * frameCount)
// 	);
//
// 	requestAnimationFrame(() => updateImage(frameIndex + 1))
// })
//
// setTimeout(() => {
// 	preloadImages()
// }, 0)